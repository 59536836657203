import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useLoaderData, useNavigate } from "@remix-run/react";
import i18next from "i18next";
import { DefaultShopSlugLoaderResponse } from "~/routes/_shopSlug/types";
import { DefaultShopIdLoaderResponse } from "~/routes/shops/$shopId/types";
import { TriangleDownIcon } from "@radix-ui/react-icons";

export const LocaleSelect = () => {
  const { shopData, locale, pageIsEmbedded } = useLoaderData<
    DefaultShopSlugLoaderResponse | DefaultShopIdLoaderResponse
  >();
  const navigate = useNavigate();
  const shopLocales = shopData.shopLocales;
  const currentLocaleName = shopLocales.find((shopLocale) => shopLocale.code === locale)?.name;

  const changeLocale = (locale: string) => {
    const search = new URLSearchParams(window.location.search);
    search.set("locale", locale);
    i18next.changeLanguage(locale);
    navigate(
      {
        search: search.toString(),
      },
      {
        replace: true,
        relative: "path",
      },
    );
  };

  if (shopLocales.length < 2) return null;

  if (pageIsEmbedded) return null;

  return (
    <div className="absolute right-4 sm:right-8 top-4 h-9">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" shape="rounded" className="bg-glass pr-3">
            {`${currentLocaleName}`}
            <TriangleDownIcon className="ml-1 h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="bg-glass rounded-2xl">
          {shopLocales.map((shopLocale) => (
            <DropdownMenuItem
              key={shopLocale.code}
              onClick={() => {
                changeLocale(shopLocale.code);
              }}
              className="rounded-xl"
            >
              {shopLocale.name}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
