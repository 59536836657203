import { BackgroundImageDerivatives } from "~/types/shopData";

function getBackgroundImageDerivative(backgroundImageUrls: BackgroundImageDerivatives, mimeType: string, size: string) {
  const typeDerivativeObject = backgroundImageUrls?.[mimeType as keyof typeof backgroundImageUrls];
  const sizeDerivative = typeDerivativeObject?.[size as keyof typeof typeDerivativeObject];
  return sizeDerivative?.toString();
}

export function getBackgroundImageUrl(
  isMobile: boolean,
  imageDerivatives: {
    mobileBackgroundImageDerivatives: BackgroundImageDerivatives;
    desktopBackgroundImageDerivatives: BackgroundImageDerivatives;
  },
  mimeType: string,
  size: string,
  default_image: string,
) {
  const { mobileBackgroundImageDerivatives, desktopBackgroundImageDerivatives } = imageDerivatives;
  const mobileBackgroundImageDerivative = getBackgroundImageDerivative(
    mobileBackgroundImageDerivatives,
    mimeType,
    size,
  );
  const backgroundImageDerivative = getBackgroundImageDerivative(desktopBackgroundImageDerivatives, mimeType, size);

  if (isMobile && mobileBackgroundImageDerivative) {
    return mobileBackgroundImageDerivative;
  } else {
    return backgroundImageDerivative || default_image;
  }
}
