import { useLoaderData } from "@remix-run/react";
import SMALL_WEBP from "./webp/bg-small.webp";
import MEDIUM_WEBP from "./webp/bg-medium.webp";
import LARGE_WEBP from "./webp/bg-large.webp";
import SMALL_JPG from "./jpg/bg-small.jpg";
import MEDIUM_JPG from "./jpg/bg-medium.jpg";
import LARGE_JPG from "./jpg/bg-large.jpg";
import { getBackgroundImageUrl } from "./helpers";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import { LocaleSelect } from "./LocaleSelect";
import { DefaultShopSlugLoaderResponse } from "~/routes/_shopSlug/types";
import { DefaultShopIdLoaderResponse } from "~/routes/shops/$shopId/types";
import BrandingFooter from "./BrandingFooter";
import { cn } from "@/lib/utils";

type BackgroundImageLoaderData = DefaultShopSlugLoaderResponse | DefaultShopIdLoaderResponse;

export default function BackgroundImage({
  displayLocaleSelector = false,
  displayBranding = false,
  children,
  className,
}: {
  displayLocaleSelector?: boolean;
  displayBranding?: boolean;
  children: React.ReactNode;
  className?: string;
}) {
  const { shopData } = useLoaderData<BackgroundImageLoaderData>();

  const { mobileBackgroundImageDerivatives, backgroundImageDerivatives: desktopBackgroundImageDerivatives } =
    shopData.userInterfaceSetting;
  const imageDerivatives = { mobileBackgroundImageDerivatives, desktopBackgroundImageDerivatives };
  const [isMobileView, setIsMobileView] = useState(false);

  const [smallWebpUrl, setSmallWebpUrl] = useState(
    getBackgroundImageUrl(isMobileView, imageDerivatives, "webp", "small", SMALL_WEBP),
  );
  const [mediumWebpUrl, setMediumWebpUrl] = useState(
    getBackgroundImageUrl(isMobileView, imageDerivatives, "webp", "medium", MEDIUM_WEBP),
  );
  const [largeWebpUrl, setLargeWebpUrl] = useState(
    getBackgroundImageUrl(isMobileView, imageDerivatives, "webp", "large", LARGE_WEBP),
  );
  const [smallJpgUrl, setSmallJpgUrl] = useState(
    getBackgroundImageUrl(isMobile, imageDerivatives, "jpg", "small", SMALL_JPG),
  );
  const [mediumJpgUrl, setMediumJpgUrl] = useState(
    getBackgroundImageUrl(isMobileView, imageDerivatives, "jpg", "medium", MEDIUM_JPG),
  );
  const [largeJpgUrl, setLargeJpgUrl] = useState(
    getBackgroundImageUrl(isMobileView, imageDerivatives, "jpg", "large", LARGE_JPG),
  );

  useEffect(() => {
    setIsMobileView(isMobile);
  }, []);

  useEffect(() => {
    setSmallWebpUrl(getBackgroundImageUrl(isMobile, imageDerivatives, "webp", "small", SMALL_WEBP));
    setMediumWebpUrl(getBackgroundImageUrl(isMobile, imageDerivatives, "webp", "medium", MEDIUM_WEBP));
    setLargeWebpUrl(getBackgroundImageUrl(isMobile, imageDerivatives, "webp", "large", LARGE_WEBP));
    setSmallJpgUrl(getBackgroundImageUrl(isMobile, imageDerivatives, "jpg", "small", SMALL_JPG));
    setMediumJpgUrl(getBackgroundImageUrl(isMobile, imageDerivatives, "jpg", "medium", MEDIUM_JPG));
    setLargeJpgUrl(getBackgroundImageUrl(isMobile, imageDerivatives, "jpg", "large", LARGE_JPG));
  }, [isMobileView]);

  return (
    <div className={cn("relative w-screen h-dvh", className)}>
      <picture className="absolute -z-10 overflow-hidden h-full w-full">
        <source media="(max-width:768px)" type="image/webp" srcSet={smallWebpUrl} />
        <source media="(max-width:1200px)" type="image/webp" srcSet={mediumWebpUrl} />
        <source media="(min-width:1201px)" type="image/webp" srcSet={largeWebpUrl} />
        <source media="(max-width:768px)" type="image/jpg" srcSet={smallJpgUrl} />
        <source media="(max-width:1200px)" type="image/jpg" srcSet={mediumJpgUrl} />
        <source media="(min-width:1201px)" type="image/jpg" srcSet={largeJpgUrl} />
        <img src={largeJpgUrl} alt="background" className="w-full h-full object-cover object-top" />
      </picture>
      {displayLocaleSelector && <LocaleSelect />}
      {children}
      {displayBranding && <BrandingFooter />}
    </div>
  );
}
